<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card v-if="!isLoading">
      <v-card-title
        ><v-spacer></v-spacer
        ><v-btn icon light @click="$router.go(-1)"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="pa-10">
        <BaseDocumentCard
          v-for="(document, i) in documents"
          :key="i"
          :document="document"
          :is-active="true"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      resourceId: '',
      resourceType: '',
      documents: [],
      isLoading: true,
      dialog: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(value) {
        this.dialog = value.meta && value.meta.dialog
        this.resourceId = this.$route.query.resourceId
        this.resourceType = this.$route.query.resourceType
        this.filterDocuments()
      }
    }
  },
  methods: {
    async filterDocuments() {
      this.isLoading = true
      const resourceId = this.resourceId
      const resourceType = this.resourceType

      this.documents = await this.$store.dispatch('document/filter', {
        resourceId,
        resourceType
      })

      this.isLoading = false
    }
  }
}
</script>
